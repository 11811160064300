/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */
@font-face {
  font-family: "Ubuntu m";
  src: url(assets/fonts/UBUNTU-M.TTF);
}
body {
  margin: 0;
}

.preloader {
  font-size: 14px;
  position: fixed;
  z-index: 100;
  /*height: 100vh;*/
  /*width: 100vw;*/
  height: 100%;
  width: 100%;
  background: url(assets/images/615467cb57761e0d880347898ddedc64.jpeg) no-repeat center;
  background-size: cover;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  transition: opacity 0.5s;
}

.delayedPreloader {
  font-size: 14px;
  position: fixed;
  z-index: 100;
  /*height: 100vh;*/
  /*width: 100vw;*/
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  transition: opacity 0.5s;
  display: none;
}

.delayedPreloader.show {
  display: flex;
}

.delayedPreloader .delayedLogo {
  background: url(assets/images/bfa067388b6f0f4747fa28fd0926318a.svg) no-repeat center/contain;
  width: 24%;
  height: 16%;
  -webkit-mask-image: -webkit-gradient(linear, right top, left top, from(black), color-stop(rgba(0, 0, 0, 0)), to(black));
  -webkit-mask-image: linear-gradient(to left, black, rgba(0, 0, 0, 0), black);
  mask-image: -webkit-gradient(linear, right top, left top, from(black), color-stop(rgba(0, 0, 0, 0)), to(black));
  mask-image: linear-gradient(to left, black, rgba(0, 0, 0, 0), black);
  -webkit-mask-size: 50% 100%;
  mask-size: 50% 100%;
  mask-mode: alpha;
  -webkit-animation: OpacityMaskAnimation 3s linear infinite;
  animation: OpacityMaskAnimation 3s linear infinite;
}

@-webkit-keyframes OpacityMaskAnimation {
  0% {
    -webkit-mask-position: 0, 0;
    mask-position: 0, 0;
  }
  100% {
    -webkit-mask-position: 100%, 0;
    mask-position: 100%, 0;
  }
}
@keyframes OpacityMaskAnimation {
  0% {
    -webkit-mask-position: 0, 0;
    mask-position: 0, 0;
  }
  100% {
    -webkit-mask-position: 100%, 0;
    mask-position: 100%, 0;
  }
}
.preloaderContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 27.44vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  font-family: "Ubuntu m", sans-serif;
  color: #F79F2D;
  font-size: 1.01rem;
  pointer-events: none;
}

.preloader #percents {
  margin-bottom: 0.811vw;
  font-size: 1.0256vw;
}

.preloaderContainer__forTwoLogo {
  height: 44vh;
}

.preloaderLogoBase {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 5.8vw;
  margin-bottom: 1.367vw;
}

.preloaderLogoProweredBy {
  background: url(assets/images/2b4399290d08e319d0dbdbbcc2e81187.png) no-repeat center/contain;
  height: 33.33%;
}

.preloaderSecondLogo {
  height: 33.33%;
}

.preloader .progressContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50%;
}

.preloader .progressContainer__forTwoLogo {
  height: 25%;
}

.progressBg {
  background: linear-gradient(to right, #F79F2D, #FF4D4D);
  background-size: contain;
  width: 100%;
  height: 1.182vw;
  border-radius: 30px;
  padding-left: 1%;
  padding-right: 1%;
  position: relative;
}

.progressBg:before {
  content: "";
  background: #9B0000;
  border-radius: 30px;
  position: absolute;
  top: 18.5%;
  bottom: 18.5%;
  left: 1%;
  right: 1%;
  max-width: 98%;
  display: block;
}

.progress {
  background: linear-gradient(to right, #F79F2D, #FF4D4D);
  border-radius: 30px;
  position: absolute;
  top: 16%;
  bottom: 16%;
  left: 0.95%;
  right: 0.95%;
  max-width: 99%;
  width: 0;
  transition: width 1s;
}

.animation {
  clip-path: inset(0 2% 0 0);
  -webkit-clip-path: inset(0 2% 0 0);
}

.fade {
  opacity: 0;
  pointer-events: none;
}

.hide {
  display: none;
}

.preloaderFooter {
  position: absolute;
  bottom: 5vh;
  height: 20vh;
  width: 20vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: white;
  font-family: "Ubuntu m";
  white-space: nowrap;
}

.logoOnlyPlay {
  background: url(assets/images/80f48155e47d3c9bb0e6e8d9470ccf2a.png) no-repeat center;
  background-size: contain;
  width: 70%;
  height: 50%;
}

@media screen and (orientation: portrait) {
  .preloader .preloaderContainer {
    transform: translateY(-30%);
    width: calc(27.44vw * 1.9);
  }

  .preloader #percents {
    margin-bottom: calc(0.811vw * 1.9);
    font-size: calc(1.0256vw * 1.9);
  }

  .preloaderLogoBase {
    height: calc(5.8vw * 1.9);
    margin-bottom: calc(1.367vw * 1.9);
  }

  .progressBg {
    height: calc(1.182vw * 1.9);
  }

  .preloader .preloaderContainer__forTwoLogo {
    height: 20vh;
  }

  .preloaderFooter {
    font-size: 0.7em;
    width: 30vw;
  }

  .delayedPreloader .delayedLogo {
    width: 48%;
    height: 32%;
  }
}
@media screen and (max-width: 375px) {
  .preloader .preloaderContainer {
    width: calc(27.44vw * 2.1);
  }

  .preloader #percents {
    margin-bottom: calc(0.811vw * 2.1);
    font-size: calc(1.0256vw * 2.1);
  }

  .preloaderLogoBase {
    height: calc(5.8vw * 2.1);
    margin-bottom: calc(1.367vw * 2.1);
  }

  .progressBg {
    height: 2.48vw;
  }

  .preloader .preloaderContainer__forTwoLogo {
    height: 20vh;
  }

  .preloaderFooter {
    font-size: 0.7em;
    width: 45vw;
  }
}
@media screen and (max-width: 768px) and (max-height: 420px) {
  .preloader .preloaderContainer {
    width: calc(27.44vw * 1.3);
  }

  .preloader #percents {
    margin-bottom: calc(0.811vw * 1.3);
    font-size: calc(1.0256vw * 1.3);
  }

  .preloaderLogoBase {
    height: calc(5.8vw * 1.3);
    margin-bottom: calc(1.367vw * 1.3);
  }

  .progressBg {
    height: 1.5vw;
  }

  .preloader .preloaderContainer__forTwoLogo {
    height: 40vh;
  }
}